import { auth } from '../../firebase.js';
import { signOut } from "firebase/auth";
import Button from '../button/button';

const LogoutButton = () => {
	const signUserOut = () => {
		signOut(auth).then(() => {
			// Sign-out successful.
		}).catch((error) => {
			// An error happened.
			console.log(error);
		});
	}

	return (
		<Button onClick={signUserOut} text="Log out"/>
	);
}

export default LogoutButton;