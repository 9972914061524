import './title.scss';

const Title = ({ title }) => {
	return (
		<h1 className='title'>
			{title}
		</h1>
	);
}

export default Title;