import { auth } from '../../firebase.js';
import { signInWithEmailAndPassword } from "firebase/auth";
import Title from '../title/title';

import './login-form.scss';
import { useState } from 'react';

const LoginForm = () => {
	const [inputs, setInputs] = useState({});
	const [errorResponse, setEerrorResponse] = useState(null);

	const handleSignIn = (event) => {
		event.preventDefault();

		const { email, password } = inputs;

		if (email && password) {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					// Signed in
					// const user = userCredential.user;
					// ...
				})
				.catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;

					if (errorCode === "auth/user-not-found") {
						setEerrorResponse("User not found");
					} else if (errorCode === "auth/wrong-password") {
						setEerrorResponse("Incorrect password");
					}

					console.log(errorMessage);
				});
		}
	}

	const handleChange = (e) => {
		setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
	};

	return (
		<section className="login-form">
			<Title title="Please log in" />
			<form className='login-form__form' onSubmit={handleSignIn}>
				<label>
					{inputs.email && <span>Email</span>}
					<input placeholder='Email' name="email" type="text" value={inputs.email || ''} onChange={handleChange} />
				</label>
				<label>
					{inputs.password && <span>Password</span>}
					<input placeholder='Password' name="password" type="password" value={inputs.password || ''} onChange={handleChange} />
				</label>
				{errorResponse && <p className='login-form__form__feedback'>{errorResponse}</p>}
				<input type="submit" value="Submit" className='button' disabled={!inputs.email || !inputs.password} />
			</form>
		</section>
	);
}

export default LoginForm;