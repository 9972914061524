import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyDnz-m3Riq1za1uh79nbRugx3PbSdLDH8w",
	authDomain: "yu-parking-4bff8.firebaseapp.com",
	databaseURL: "https://yu-parking-4bff8-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "yu-parking-4bff8",
	storageBucket: "yu-parking-4bff8.appspot.com",
	messagingSenderId: "668576186759",
	appId: "1:668576186759:web:9bff36d00972badecb9322"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Get a reference to the database service
const db = getDatabase(app);

export { firebaseConfig, auth, db };
