import { auth } from './firebase.js';
import { onAuthStateChanged } from "firebase/auth";
import { useState } from 'react';

import './fonts.css';
import './app.scss';
import YuLogo from './components/yu-logo/yu-logo';
import Title from './components/title/title';
import ParkingMap from './components/parking-map/parking-map';
import LoginForm from './components/login-form/login-form';
import { useEffect } from 'react';

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');


	const handleDarkmode = (e) => {
		const darkModeOn = e.matches; // true if dark mode is enabled
		const favicon = document.querySelector('link[rel="shortcut icon"]'); // get favicon-192.png element
		const largeFavicon = document.querySelector('link[rel="icon"]'); // get favicon.ico element
		const appleFavicon = document.querySelector('link[rel="apple-touch-icon"]'); // get favicon.ico element

		if (!favicon || !largeFavicon || !appleFavicon) {
			return; // where are our favicon elements???
		}

		// replace icons with dark/light themes as appropriate
		if (darkModeOn) {
			favicon.href = '/favicon-dark-mode.ico';
			largeFavicon.href = `${process.env.PUBLIC_URL}logo-dark-mode-196.png`;
			appleFavicon.href = `${process.env.PUBLIC_URL}apple-logo-dark-mode-144.png`;
		} else {
			favicon.href = '/favicon.ico';
			largeFavicon.href = `${process.env.PUBLIC_URL}logo-light-mode-196.png`;
			appleFavicon.href = `${process.env.PUBLIC_URL}apple-logo-light-mode-144.png`;
		}
	}
	handleDarkmode(darkModeMediaQuery);

	useEffect(() => {
		if (darkModeMediaQuery?.addEventListener) {
			darkModeMediaQuery.addEventListener('change', handleDarkmode);
		} else {
			darkModeMediaQuery.addListener(handleDarkmode);
		}

		return function cleanup() {
			if (darkModeMediaQuery?.addEventListener) {
				darkModeMediaQuery.removeEventListener('change', handleDarkmode);
			} else {
				darkModeMediaQuery.removeListener(handleDarkmode);
			}
		};
	});

	onAuthStateChanged(auth, (user) => {
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			// const uid = user.uid;
			setIsLoggedIn(true);
		} else {
			// User is signed out
			setIsLoggedIn(false);
		}
	});

	return (
		<main className="app">
			<div className='app__header'>
				<YuLogo />
				{isLoggedIn && <Title title="Parking spots" />}
			</div>
			{isLoggedIn ? <ParkingMap /> : <LoginForm />}
		</main>
	);
}

export default App;
