import { db } from '../../firebase.js';
import { ref, onValue, update } from "firebase/database";
import LogOutButton from '../logout-button/logout-button';

import './parking-map.scss';
import ParkingMapImage from '../../assets/parking_map_cropped.jpg';
import { useState, useRef, useEffect } from 'react';

const Map = () => {
	const [parkingMapData, setParkingMapData] = useState(null);
	const [parkingMapImageDimensions, setParkingMapImageDimensions] = useState({ width: 0, height: 0 });
	const [isParkingMapImageLoaded, setIsParkingMapImageLoaded] = useState(false);
	const parkingMapImageRef = useRef(null);

	useEffect(() => {
		const spotsRef = ref(db, '/');

		onValue(spotsRef, (snapshot) => {
			const data = snapshot.val();

			setParkingMapData(data);
		});
	}, []);

	const clickedSpot = (id) => {
		const updatedMapLandscapeData = parkingMapData;
		const spotToUpdate = Object.values(updatedMapLandscapeData).find(spot => spot.id === id);

		spotToUpdate.isOccupied = !spotToUpdate.isOccupied;

		// Write the updated spots data.
		const updates = {};
		updates['/' + id] = { ...spotToUpdate };

		return update(ref(db), updates);
	}

	const onImgLoad = () => {
		setIsParkingMapImageLoaded(true);
		setParkingMapImageDimensions({
			width: parkingMapImageRef.current.naturalWidth,
			height: parkingMapImageRef.current.naturalHeight
		});
	}

	const getValueInPercent = (value, coord) => {
		switch (coord) {
			case 'x':
				return `${100 * (value / parkingMapImageDimensions.width)}%`;
			case 'y':
			default:
				return `${100 * (value / parkingMapImageDimensions.height)}%`;
		}
	};

	return (
		<section className={`parking-map ${isParkingMapImageLoaded && parkingMapData && 'parking-map--loaded'}`}>
			<div className="parking-map__logout-button-container">
				<LogOutButton />
			</div>
			<div className='parking-map__spots'>
				{parkingMapData && Object.values(parkingMapData).map((spot) => {
					const { id, name, x, y, width, height, isOccupied } = spot;
					return (
						<div
							key={id}
							className={`parking-map__spot ${isOccupied && 'parking-map__spot--is-occupied'}`}
							onClick={() => { clickedSpot(id); }}
							style={{
								top: getValueInPercent(y, 'y'),
								left: getValueInPercent(x, 'x'),
								width: getValueInPercent(width, 'x'),
								height: getValueInPercent(height, 'y')
							}}
						>
							<span className='parking-map__spot-name'>{name}</span>
						</div>
					);
				})}
			</div>
			<img ref={parkingMapImageRef} className='parking-map__image' onLoad={onImgLoad} src={ParkingMapImage} alt='map' />
		</section>
	);
}

export default Map;